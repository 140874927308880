import React from "react";
import cosmoHome from "../assets/Screenshot (76).png";
import ecommerceWebsite from "../assets/Screenshot (85).png";
import gymApp from "../assets/Screenshot (106).png";

const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      src: cosmoHome,
      link: "https://hzfhzf137.github.io/CosmoCommunication", 
    },
    {
      id: 2,
      src: ecommerceWebsite,
      link: "https://hzfhzf137.github.io/clientEcommerceWebsite/",
    },
    {
      id: 3,
      src: gymApp,
      link: "https://hzfhzf137.github.io/clientGymApp/", 
    },
  ];

  return (
    <div
      name="portfolio"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen portfolio"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Portfolio
          </p>
          <p className="py-6">Check out some of my work right here</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {portfolios.map(({ id, src, link }) => (
            <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
              <img
                src={src}
                alt="projects"
                className="rounded-md duration-200 hover:scale-105"
              />
              <a
                href={link}
                target="_blank" // Opens link in a new tab
                rel="noopener noreferrer" // Security best practice for external links
                className="block text-center mt-2 font-bold text-blue-500 hover:underline"
              >
                Click Here to Visit
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
